<template>
  <TourTooltip
    :steps="steps"
    :has-previous-step="hasPreviousStep"
    :has-next-step="hasNextStep"
    @previous="previousStep($event)"
    @next="nextStep($event)"
    @skip="$emit('skip')"
  />
</template>

<script>
import TourTooltip from '@/components/TourTooltip'

export default {
  name: 'TourHome',
  components: {
    TourTooltip,
  },
  data() {
    return {
      hasPreviousStep: false,
      hasNextStep: true,
      steps: [
        {
          target: '#v-step-1',
          content: `
            <h4 class="v-step__title">Seus Canais de Vídeos</h4>
            <span class="v-step__body">
              Aqui você encontra todos os canais de vídeos disponíveis para você.
            </span>`,
          params: {
            placement: 'top',
          },
        },
        {
          target: '#v-step-2',
          content: `
            <h4 class="v-step__title">Navegue entre os canais</h4>
            <span class="v-step__body">
              Utilize a setinha para mostrar mais canais.
            </span>`,
          params: {
            placement: 'top',
          },
        },
        {
          target: '#v-step-3',
          content: `
            <h4 class="v-step__title">Sua Sala de Aula</h4>
            <span class="v-step__body">
              Aqui você encontra uma videoaula para cada capitulo dos seus livros.
            </span>`,
          params: {
            placement: 'bottom',
          },
        },
        {
          target: '#v-step-4',
          content: `
            <h4 class="v-step__title">Escolha a disciplina</h4>
            <span class="v-step__body">
              Clique na disciplina para visualizar as videoaulas.
            </span>`,
          params: {
            placement: 'top',
          },
        },
      ],
    }
  },
  mounted() {
    const tours = this.$tours['onboarding']

    this.$watch(() =>
      tours.currentStep,
      () => this.setShowButtons(tours)
    )
  },
  methods: {
    setShowButtons(tours) {
      this.hasPreviousStep = !tours.isFirst
    },
    nextStep(tour) {
      if (tour.currentStep === tour.steps.length - 1) {
        this.$router.push({
          name: 'videos',
          params: { channel: '1' }
        })
        this.$emit('next')
      } else if (tour.currentStep === 1 && this.$mq === 'x_large') {
        tour.nextStep()
        setTimeout(() => window.scrollTo(0, 120), 1)
      } else {
        tour.nextStep()
      }
    },
    previousStep(tour) {
      if (tour.currentStep === 0) {
        this.$emit('previous')
      } else {
        tour.previousStep()
      }
    },
  },
}
</script>
